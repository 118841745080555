import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-10" }
const _hoisted_2 = { class: "card-body pt-9 pb-0 min-h-300px" }
const _hoisted_3 = { class: "d-flex flex-wrap flex-sm-nowrap mb-3" }
const _hoisted_4 = { class: "flex-grow-1" }
const _hoisted_5 = { class: "align-items-sm-start mt-5" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: " align-items-sm-start" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "align-items-sm-start mt-5" }
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.country.list, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "symbol symbol-100px  me-5 mt-5 align-items-sm-start cursor-pointer",
                key: item.key,
                onClick: ($event: any) => (_ctx.setCountry(item.key))
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass([`bg-primary text-white`, "symbol-label fw-bolder"])
                }, _toDisplayString(item.title), 1)
              ], 8, _hoisted_6))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alphabet, (alp, key) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "symbol symbol-45px me-5 mt-5 align-items-sm-start cursor-pointer",
                key: key,
                onClick: ($event: any) => (_ctx.setValue(alp))
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass([`bg-secondary text-dark`, "symbol-label fw-bolder btn btn-active-primary"])
                }, _toDisplayString(alp), 1)
              ], 8, _hoisted_8))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (int, key) => {
              return _createElementVNode("div", {
                class: "symbol symbol-45px me-5 align-items-sm-start cursor-pointer",
                key: key,
                onClick: ($event: any) => (_ctx.setValue(key))
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass([`bg-secondary text-dark`, "symbol-label fw-bolder btn btn-active-primary"])
                }, _toDisplayString(key), 1)
              ], 8, _hoisted_10)
            }), 64))
          ])
        ])
      ])
    ])
  ]))
}