
import {defineComponent} from "vue";

export default defineComponent({
  name: "kt-account",
  components: {
  },
  methods: {
    setValue (value){
      this.$emit('setValue', value);
    },
    setCountry (value){
      this.$emit('setCountry', value);
    }
  },
  setup(){
    const alphabet = ["A","B","C","Ç","D","E","F","G","H","I","İ","J","K","L","M","N","O","Ö","P","Q","R","S","Ş","T","U","Ü","V","W","X","Y","Z"];
    const country = {
      list: [
        {
          title: "Türkiye",
          key: "TR",
        },
        {
          title: "Kuzey Kıbrıs",
          key: "TRNC",
        }
        ]
    };
    return {
        alphabet,
        country
    };
  }
});
